import hooks from "../.build/hooks.raw.js";
import * as formula from "./formulas.js";

const ns = "sds";
const panel = document.getElementById("hooks-panel");

const getCategories = (props) => {
  const track = {
    color: [],
    spacing: [],
    sizing: [],
    shadow: [],
    font: [],
    radius: [],
  };
  Object.keys(props).map((prop) => {
    const category = props[prop].category;
    if (track.hasOwnProperty(category)) {
      track[category].push(props[prop]);
    }
  });
  return track;
};

// console.log(getCategories(hooks.props));

const getBrandColors = getCategories(hooks.props).color.filter((foo) => {
  return foo.name.includes("brand") && foo.group !== "reference";
});

const getNeutralColors = getCategories(hooks.props).color.filter((foo) => {
  return foo.name.includes("neutral") && foo.group !== "reference";
});

const getFontScale = getCategories(hooks.props).font.filter((foo) => {
  return foo.name.includes("scale");
});

const getRadius = getCategories(hooks.props).radius.filter((foo) => {
  return !foo.name.includes("circle") && foo.scope === "global";
});

const getShadow = getCategories(hooks.props).shadow.filter((foo) => {
  return foo.scope === "global";
});

const getSpacing = getCategories(hooks.props).spacing.filter((foo) => {
  return foo.scope === "global";
});

export const setPaletteFromColor = (seed, name) => {
  let hook = name;
  let computedValue = seed;
  if (seed.startsWith("--")) {
    hook = seed.match(/\b[a-z]+\b/g).join("-");
    computedValue = getComputedStyle(document.documentElement)
      .getPropertyValue(seed)
      .replace(/\s/g, "");
  }
  const generatedPalette = formula.getPaletteFromColor(computedValue).palette;
  Object.keys(generatedPalette).map((range) => {
    document.documentElement.style.setProperty(
      `--${ns}-g-${hook}-${range}`,
      generatedPalette[range]
    );
  });
};

export const setFontScale = (ratio) => {
  const start = -4;
  const end = 10;
  const spread = [...Array(end - start + 1).keys()].map((x) => x + start);

  spread.map((interval) => {
    let next = interval;
    const value = Math.pow(ratio, next);
    if (interval < 0) {
      next = `neg${interval}`;
    }
    if (interval === 0) {
      next = "base";
    }

    if (next !== "base") {
      document.documentElement.style.setProperty(
        `--${ns}-g-font-scale-${next}`,
        `calc(${value} * var(--${ns}-g-font-scale-base))`
      );
    }
  });
};

export const setFontBase = (base) => {
  document.documentElement.style.setProperty(
    `--${ns}-g-font-scale-base`,
    `${base}rem`
  );
};

const setScale = (category, operator = "*") => {
  let props = [];
  category.map((prop) => {
    props.push(
      `--${ns}-g-${prop.name}: calc(${prop.value} ${operator} var(--${ns}-g-${prop.category}-ratio));`
    );
  });
  return `:root {
    ${props.join("\n    ")}
  }`;
};

const injectProps = () => {
  const style = document.createElement("style");
  style.innerHTML = `
    ${setScale(getRadius)}
    ${setScale(getSpacing)}
  `;
  document.querySelector("head").appendChild(style);
};

const init = () => {
  console.log("Styling Hooks Panel Initialized...");
  injectProps();
};

export default init;
