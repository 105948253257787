import { LightningElement, track } from "lwc";
import { topLevelNavigation } from "./mockNav";
// external dependencies are also able to be imported/resolved.
// for example: import classNames from 'classnames';

const cmps = [
  "primitive-alert",
  "com-button",
  "primitive-button",
  "com-dropdown",
  "primitive-dropdown",
  "com-icon",
  "primitive-icon",
  "primitive-link",
  "com-menu",
  "primitive-menu",
  "primitive-overlay",
  "com-text-input",
  "primitive-text-input",
];

const customCmps = ["com-header", "com-hero"];

export default class App extends LightningElement {
  static renderMode = `light`;
  navigation = topLevelNavigation;
  customerLinks = [
    {
      id: "24tiuh43tiu",
      properties: {
        text: "help",
        href: "#",
      },
    },
    {
      id: "f4f425",
      properties: {
        text: "exchanges & returns",
        href: "#",
      },
    },
    {
      id: "g533gd",
      properties: {
        text: "order tracker",
        href: "#",
      },
    },
    {
      id: "dsfvdfbdfb",
      properties: {
        text: "become a member",
        href: "#",
      },
    },
  ];

  renderedCallback() {
    const all = document.body.querySelectorAll("*");
    const controls = document.querySelectorAll(".x-ray-controls input");
    all.forEach((el) => {
      if ([...cmps, ...customCmps].includes(el.localName)) {
        this.handleForm(controls, el);
        el.classList.toggle("x-ray");
      }
    });
  }

  handleForm(controls, target) {
    controls.forEach((control) => {
      const element = document.getElementById(control.id);
      element.addEventListener("input", (e) => {
        if (target.localName.includes(control.id)) {
          console.log(control.id, target.localName, target);
          target.classList.toggle("scale-up");
        }
      });
    });
  }
}
