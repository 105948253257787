const topLevelNavigation = [
  {
    id: "ID3453453",
    properties: {
      text: "Men",
      href: "#",
      navigation: [
        {
          id: "fofsdfs12123",
          properties: {
            hero: "true",
            url: ""
          }
        },
        {
          id: "ID9423094391",
          properties: {
            text: "Featured",
            href: "#",
          },
          subNavigation: [
            { id: "ID939348", properties: { text: "new arrivals", href: "#" , featuredItem: "featured" } },
            { id: "ID939341", properties: { text: "best sellers", href: "#" } },
            {
              id: "ID939342",
              properties: { text: "only at Adidas", href: "#" },
            },
            {
              id: "ID939343",
              properties: { text: "member exclusives", href: "#" },
            },
            {
              id: "ID939344",
              properties: { text: "release dates", href: "#" },
            },
          ],
        },
        {
          id: "ID94230943908",
          properties: {
            text: "Shoes",
            href: "#",
          },
          subNavigation: [
            { id: "ID939348", properties: { text: "sneakers", href: "#" } },
            { id: "ID939341", properties: { text: "running", href: "#" } },
            {
              id: "ID939342",
              properties: { text: "sandals & slides", href: "#" },
            },
            {
              id: "ID939343",
              properties: { text: "outdoor & hiking", href: "#" },
            },
            { id: "ID939344", properties: { text: "soccer", href: "#" } },
          ],
        },
        {
          id: "ID9423094393",
          properties: {
            text: "Clothing",
            href: "#",
          },
          subNavigation: [
            {
              id: "ID939348",
              properties: { text: "t-shirts & tops", href: "#" },
            },
            { id: "ID939341", properties: { text: "shorts", href: "#" } },
            {
              id: "ID939342",
              properties: { text: "pants", href: "#" },
            },
            {
              id: "ID939343",
              properties: { text: "hoodies & sweatshirts", href: "#" },
            },
            { id: "ID939344", properties: { text: "tracksuits", href: "#" } },
          ],
        },
        {
          id: "ID94230943901",
          properties: {
            text: "Accessories",
            href: "#",
          },
          subNavigation: [
            {
              id: "ID939348",
              properties: { text: "bags & backpacks", href: "#" },
            },
            { id: "ID939341", properties: { text: "balls", href: "#" } },
            {
              id: "ID939343",
              properties: { text: "gloves", href: "#" },
            },
            {
              id: "ID939344",
              properties: { text: "hats & headbands", href: "#" },
            },
            {
              id: "ID939342",
              properties: { text: "socks", href: "#" },
            },
          ],
        },
      ],
    },
  },
  {
    id: "a90s87fd9a0s",
    properties: {
      text: "Women",
      href: "#",
      navigation: [
        {
          id: "ID9423094391",
          properties: {
            text: "Featured",
            href: "#",
          },
          subNavigation: [
            { id: "ID939348", properties: { text: "new arrivals", href: "#" } },
            { id: "ID939341", properties: { text: "best sellers", href: "#" } },
            {
              id: "ID939342",
              properties: { text: "only at Adidas", href: "#" },
            },
            {
              id: "ID939343",
              properties: { text: "member exclusives", href: "#" },
            },
            {
              id: "ID939344",
              properties: { text: "release dates", href: "#" },
            },
          ],
        },
        {
          id: "ID94230943902",
          properties: {
            text: "shoes",
            href: "#",
          },
          subNavigation: [
            { id: "ID939348", properties: { text: "sneakers", href: "#" } },
            { id: "ID939341", properties: { text: "running", href: "#" } },
            {
              id: "ID939342",
              properties: { text: "sandals & slides", href: "#" },
            },
            {
              id: "ID939343",
              properties: { text: "platform shoes", href: "#" },
            },
            {
              id: "ID939344",
              properties: { text: "workout & gym", href: "#" },
            },
          ],
        },
        {
          id: "ID94230943903",
          properties: {
            text: "clothing",
            href: "#",
          },
          subNavigation: [
            {
              id: "ID939348",
              properties: { text: "t-shirts & tops", href: "#" },
            },
            { id: "ID939341", properties: { text: "shorts", href: "#" } },
            {
              id: "ID939343",
              properties: { text: "sports bras", href: "#" },
            },
            { id: "ID939344", properties: { text: "plus size", href: "#" } },
            {
              id: "ID939342",
              properties: { text: "pants", href: "#" },
            },
          ],
        },
        {
          id: "ID94230943904",
          properties: {
            text: "accessories",
            href: "#",
          },
          subNavigation: [
            {
              id: "ID939348",
              properties: { text: "bags & backpacks", href: "#" },
            },
            { id: "ID939341", properties: { text: "balls", href: "#" } },
            {
              id: "ID939343",
              properties: { text: "gloves", href: "#" },
            },
            {
              id: "ID939344",
              properties: { text: "hats & headbands", href: "#" },
            },
            {
              id: "ID939342",
              properties: { text: "socks", href: "#" },
            },
          ],
        },
      ],
    },
  },
  {
    id: "oaisoid393",
    properties: {
      text: "Kids",
      href: "#",
      navigation: [
        {
          id: "ID94230943905",
          properties: {
            text: "shoes",
            href: "#",
          },
          subNavigation: [
            { id: "ID939348", properties: { text: "sneakers", href: "#" } },
            { id: "ID939341", properties: { text: "running", href: "#" } },
            {
              id: "ID939342",
              properties: { text: "sandals & slides", href: "#" },
            },
            {
              id: "ID939343",
              properties: { text: "outdoor & hiking", href: "#" },
            },
            { id: "ID939344", properties: { text: "soccer", href: "#" } },
          ],
        },
      ],
    },
  },
  {
    id: "asdf098979978d",
    properties: {
      text: "End of Season Sale",
      href: "#",
      navigation: [
        {
          id: "ID94230943906",
          properties: {
            text: "shoes",
            href: "#",
          },
          subNavigation: [
            { id: "ID939348", properties: { text: "sneakers", href: "#" } },
            { id: "ID939341", properties: { text: "running", href: "#" } },
            {
              id: "ID939342",
              properties: { text: "sandals & slides", href: "#" },
            },
            {
              id: "ID939343",
              properties: { text: "outdoor & hiking", href: "#" },
            },
            { id: "ID939344", properties: { text: "soccer", href: "#" } },
          ],
        },
      ],
    },
  },
  {
    id: "43431f2f2f",
    properties: {
      text: "3 Strip Life",
      href: "#",
      navigation: [
        {
          id: "ID94230943907",
          properties: {
            text: "shoes",
            href: "#",
          },
          subNavigation: [
            { id: "ID939348", properties: { text: "sneakers", href: "#" } },
            { id: "ID939341", properties: { text: "running", href: "#" } },
            {
              id: "ID939342",
              properties: { text: "sandals & slides", href: "#" },
            },
            {
              id: "ID939343",
              properties: { text: "outdoor & hiking", href: "#" },
            },
            { id: "ID939344", properties: { text: "soccer", href: "#" } },
          ],
        },
      ],
    },
  },
];

export { topLevelNavigation };
