import { LightningElement, api } from "lwc";

const parser = new DOMParser();

export default class PrimitiveIcon extends LightningElement {
  _initialized;

  @api symbol = "smiley_and_people";

  @api set = "static/icons/slds/utility-sprite/svg/symbols.svg";

  @api src;

  async fetchIcon(src) {
    const request = await fetch(src).then(async (response) => {
      if (response.ok) {
        const div = document.createElement("div");
        div.innerHTML = await response.text();
        const svg = div.firstElementChild;
        return {
          src,
          svg: svg.outerHTML,
        };
      } else {
        throw Error();
      }
    });
    return request;
  }

  async createIcon() {
    const svgContainer = this.template.querySelector("slot");
    const svg = await this.fetchIcon(this.src);
    const doc = parser.parseFromString(svg.svg, "text/html");
    const svgEl = doc.body.querySelector("svg");
    svgEl.setAttribute("aria-hidden", "true");
    svgContainer.replaceWith(svgEl);
  }

  async findSymbol() {
    const svgContainer = this.template.querySelector("slot");
    svgContainer.innerHTML = `
      <svg>
        <use xlink:href="/${this.set}#${this.symbol}" />
      </svg>
    `;
  }

  get _slottedChildren() {
    const slot = this.template.querySelector("slot");
    return slot.assignedElements({ flatten: true });
  }

  renderedCallback() {
    if (this._initialized) {
      return;
    }
    this._initialized = true;
    const slot = this.template.querySelector("slot");
    const slottedContent = slot && this._slottedChildren;
    if (slottedContent.length === 0) {
      if (!this.src) {
        this.findSymbol();
      } else {
        this.createIcon();
      }
    }
  }
}
