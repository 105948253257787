const topLevelNavigation = [
  {
    id: "asdf098979d978d",
    properties: {
      text: "New",
      href: "#",
      navigation: [
        {
          id: "ID94230943906",
          properties: {
            text: "Shoes",
            href: "#",
          },
          subNavigation: [
            { id: "ID939348", properties: { text: "sneakers", href: "#" } },
            { id: "ID939341", properties: { text: "running", href: "#" } },
            {
              id: "ID939342",
              properties: { text: "sandals & slides", href: "#" },
            },
            {
              id: "ID939343",
              properties: { text: "outdoor & hiking", href: "#" },
            },
            { id: "ID939344", properties: { text: "soccer", href: "#" } },
          ],
        },
        {
          id: "ID94230d943dfd906",
          properties: {
            text: "Basketball",
            href: "#",
          },
          subNavigation: [
            { id: "ID939348", properties: { text: "sneakers", href: "#" } },
            { id: "ID939341", properties: { text: "running", href: "#" } },
            {
              id: "ID939342",
              properties: { text: "sandals & slides", href: "#" },
            },
            {
              id: "ID939343",
              properties: { text: "outdoor & hiking", href: "#" },
            },
            { id: "ID939344", properties: { text: "soccer", href: "#" } },
          ],
        },
        {
          id: "ID9423df0d943906",
          properties: {
            text: "Football",
            href: "#",
          },
          subNavigation: [
            { id: "ID939348", properties: { text: "sneakers", href: "#" } },
            { id: "ID939341", properties: { text: "running", href: "#" } },
            {
              id: "ID939342",
              properties: { text: "sandals & slides", href: "#" },
            },
            {
              id: "ID939343",
              properties: { text: "outdoor & hiking", href: "#" },
            },
            { id: "ID939344", properties: { text: "soccer", href: "#" } },
          ],
        },
      ],
    },
  },
  {
    id: "ID3453453",
    properties: {
      text: "Men",
      href: "#",
      navigation: [
        {
          id: "fofsdfs12123",
          properties: {
            hero: "true",
            url: "",
          },
        },
        {
          id: "ID9423094391",
          properties: {
            text: "Featured",
            href: "#",
          },
          subNavigation: [
            { id: "ID939341", properties: { text: "best sellers", href: "#" } },
            {
              id: "ID939342",
              properties: { text: "only at Adidas", href: "#" },
            },
            {
              id: "ID939343",
              properties: { text: "member exclusives", href: "#" },
            },
            {
              id: "ID939344",
              properties: { text: "release dates", href: "#" },
            },
          ],
        },
        {
          id: "ID94230943908",
          properties: {
            text: "Shoes",
            href: "#",
          },
          subNavigation: [
            { id: "ID939348", properties: { text: "sneakers", href: "#" } },
            { id: "ID939341", properties: { text: "running", href: "#" } },
            {
              id: "ID939342",
              properties: { text: "sandals & slides", href: "#" },
            },
            {
              id: "ID939343",
              properties: { text: "outdoor & hiking", href: "#" },
            },
            { id: "ID939344", properties: { text: "soccer", href: "#" } },
          ],
        },
        {
          id: "ID9423094393",
          properties: {
            text: "Clothing",
            href: "#",
          },
          subNavigation: [
            {
              id: "ID939348",
              properties: { text: "t-shirts & tops", href: "#" },
            },
            { id: "ID939341", properties: { text: "shorts", href: "#" } },
            {
              id: "ID939342",
              properties: { text: "pants", href: "#" },
            },
            {
              id: "ID939343",
              properties: { text: "hoodies & sweatshirts", href: "#" },
            },
            { id: "ID939344", properties: { text: "tracksuits", href: "#" } },
          ],
        },
        {
          id: "ID94230943901",
          properties: {
            text: "Accessories",
            href: "#",
          },
          subNavigation: [
            {
              id: "ID939348",
              properties: { text: "bags & backpacks", href: "#" },
            },
            { id: "ID939341", properties: { text: "balls", href: "#" } },
            {
              id: "ID939343",
              properties: { text: "gloves", href: "#" },
            },
            {
              id: "ID939344",
              properties: { text: "hats & headbands", href: "#" },
            },
            {
              id: "ID939342",
              properties: { text: "socks", href: "#" },
            },
          ],
        },
      ],
    },
  },
  {
    id: "a90s87fd9a0s",
    properties: {
      text: "Women",
      href: "#",
      navigation: [
        {
          id: "ID9423094391",
          properties: {
            text: "Featured",
            href: "#",
          },
          subNavigation: [
            { id: "ID939348", properties: { text: "new arrivals", href: "#" } },
            { id: "ID939341", properties: { text: "best sellers", href: "#" } },
            {
              id: "ID939342",
              properties: { text: "only at Adidas", href: "#" },
            },
            {
              id: "ID939343",
              properties: { text: "member exclusives", href: "#" },
            },
            {
              id: "ID939344",
              properties: { text: "release dates", href: "#" },
            },
          ],
        },
        {
          id: "ID94230943902",
          properties: {
            text: "shoes",
            href: "#",
          },
          subNavigation: [
            { id: "ID939348", properties: { text: "sneakers", href: "#" } },
            { id: "ID939341", properties: { text: "running", href: "#" } },
            {
              id: "ID939342",
              properties: { text: "sandals & slides", href: "#" },
            },
            {
              id: "ID939343",
              properties: { text: "platform shoes", href: "#" },
            },
            {
              id: "ID939344",
              properties: { text: "workout & gym", href: "#" },
            },
          ],
        },
        {
          id: "ID94230943903",
          properties: {
            text: "clothing",
            href: "#",
          },
          subNavigation: [
            {
              id: "ID939348",
              properties: { text: "t-shirts & tops", href: "#" },
            },
            { id: "ID939341", properties: { text: "shorts", href: "#" } },
            {
              id: "ID939343",
              properties: { text: "sports bras", href: "#" },
            },
            { id: "ID939344", properties: { text: "plus size", href: "#" } },
            {
              id: "ID939342",
              properties: { text: "pants", href: "#" },
            },
          ],
        },
        {
          id: "ID94230943904",
          properties: {
            text: "accessories",
            href: "#",
          },
          subNavigation: [
            {
              id: "ID939348",
              properties: { text: "bags & backpacks", href: "#" },
            },
            { id: "ID939341", properties: { text: "balls", href: "#" } },
            {
              id: "ID939343",
              properties: { text: "gloves", href: "#" },
            },
            {
              id: "ID939344",
              properties: { text: "hats & headbands", href: "#" },
            },
            {
              id: "ID939342",
              properties: { text: "socks", href: "#" },
            },
          ],
        },
      ],
    },
  },
  {
    id: "oaisoid393",
    properties: {
      text: "Kids",
      href: "#",
      navigation: [
        {
          id: "ID94230943905",
          properties: {
            text: "shoes",
            href: "#",
          },
          subNavigation: [
            { id: "ID939348", properties: { text: "sneakers", href: "#" } },
            { id: "ID939341", properties: { text: "running", href: "#" } },
            {
              id: "ID939342",
              properties: { text: "sandals & slides", href: "#" },
            },
            {
              id: "ID939343",
              properties: { text: "outdoor & hiking", href: "#" },
            },
            { id: "ID939344", properties: { text: "soccer", href: "#" } },
          ],
        },
      ],
    },
  },
  {
    id: "asdf098979978d",
    properties: {
      text: "Accessories",
      href: "#",
      navigation: [
        {
          id: "ID94230943906",
          properties: {
            text: "shoes",
            href: "#",
          },
          subNavigation: [
            { id: "ID939348", properties: { text: "sneakers", href: "#" } },
            { id: "ID939341", properties: { text: "running", href: "#" } },
            {
              id: "ID939342",
              properties: { text: "sandals & slides", href: "#" },
            },
            {
              id: "ID939343",
              properties: { text: "outdoor & hiking", href: "#" },
            },
            { id: "ID939344", properties: { text: "soccer", href: "#" } },
          ],
        },
      ],
    },
  },
  {
    id: "43431f2f2f",
    properties: {
      text: "Sale",
      href: "#",
      navigation: [
        {
          id: "ID942309aa43906",
          properties: {
            text: "Shoes",
            href: "#",
          },
          subNavigation: [
            { id: "ID9391348", properties: { text: "sneakers", href: "#" } },
            { id: "ID9392341", properties: { text: "running", href: "#" } },
            {
              id: "ID9339342",
              properties: { text: "sandals & slides", href: "#" },
            },
            {
              id: "ID9394343",
              properties: { text: "outdoor & hiking", href: "#" },
            },
            { id: "ID9359344", properties: { text: "soccer", href: "#" } },
          ],
        },
        {
          id: "ID94230d6943dfd906",
          properties: {
            text: "Basketball",
            href: "#",
          },
          subNavigation: [
            { id: "ID9379348", properties: { text: "sneakers", href: "#" } },
            { id: "ID9389341", properties: { text: "running", href: "#" } },
            {
              id: "ID9399342",
              properties: { text: "sandals & slides", href: "#" },
            },
            {
              id: "ID93923343",
              properties: { text: "outdoor & hiking", href: "#" },
            },
            { id: "ID93975344", properties: { text: "soccer", href: "#" } },
          ],
        },
        {
          id: "ID9423df0d9n43906",
          properties: {
            text: "Football",
            href: "#",
          },
          subNavigation: [
            { id: "ID9c39348", properties: { text: "sneakers", href: "#" } },
            { id: "ID9n39341", properties: { text: "running", href: "#" } },
            {
              id: "ID9s39342",
              properties: { text: "sandals & slides", href: "#" },
            },
            {
              id: "ID939a343",
              properties: { text: "outdoor & hiking", href: "#" },
            },
            { id: "ID939p344", properties: { text: "soccer", href: "#" } },
          ],
        },
      ],
    },
  },
];

export { topLevelNavigation };
