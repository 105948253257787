import PrimitiveDropdown from "primitive/dropdown";
import template from "../../primitive/dropdown/dropdown.html";
import stylesheets from "./dropdown.css";

export default class ComNav extends PrimitiveDropdown {
  render() {
    template.stylesheets = stylesheets;
    template.stylesheetToken = "dropdown";
    return template;
  }
}
