const topLevelNavigation = [
  {
    id: "asdf098979d978d",
    properties: {
      text: "Coffee",
      href: "#",
      navigation: [
        {
          id: "ID94230943906",
          properties: {
            text: "Beans",
            href: "#",
          },
          subNavigation: [
            {
              id: "ID939348",
              properties: { text: "Shop Alpine Coffee", href: "#" },
            },
            {
              id: "ID939341",
              properties: { text: "Shop Original Espresso", href: "#" },
            },
            {
              id: "ID939342",
              properties: { text: "Shop More", href: "#" },
            },
          ],
        },
        {
          id: "ID94230d943dfd906",
          properties: {
            text: "Capsules",
            href: "#",
          },
          subNavigation: [
            {
              id: "ID939348",
              properties: { text: "Explore Seasonal Capsules", href: "#" },
            },
            { id: "ID939341", properties: { text: "Best Sellers", href: "#" } },
            {
              id: "ID939342",
              properties: { text: "Set Up Auto-Delivery", href: "#" },
            },
          ],
        },
        {
          id: "ID9423df0d943906",
          properties: {
            text: "Capsules Recycling",
            href: "#",
          },
          subNavigation: [
            {
              id: "ID939348",
              properties: { text: "Why Use Aluminum", href: "#" },
            },
            {
              id: "ID939341",
              properties: { text: "Order Recycling Bag", href: "#" },
            },
          ],
        },
      ],
    },
  },
  {
    id: "ID3453453",
    properties: {
      text: "Energy Drinks",
      href: "#",
      navigation: [
        {
          id: "fofsdfs12123",
          properties: {
            hero: "true",
            url: "",
          },
        },
        {
          id: "ID9423094391",
          properties: {
            text: "Drinks",
            href: "#",
          },
          subNavigation: [
            {
              id: "ID939341",
              properties: { text: "Best Drinks of 2023", href: "#" },
            },
            {
              id: "ID939342",
              properties: { text: "Explore Seasonal Drinks", href: "#" },
            },
            {
              id: "ID939343",
              properties: { text: "Shop More", href: "#" },
            },
          ],
        },
        {
          id: "ID94230943908",
          properties: {
            text: "Gels",
            href: "#",
          },
          subNavigation: [
            {
              id: "ID939348",
              properties: { text: "Explore Seasonal Gels", href: "#" },
            },
            { id: "ID939341", properties: { text: "Best Sellers", href: "#" } },
            {
              id: "ID939342",
              properties: { text: "Shop More", href: "#" },
            },
          ],
        },
        {
          id: "ID9423094393",
          properties: {
            text: "Packs",
            href: "#",
          },
          subNavigation: [
            {
              id: "ID939348",
              properties: { text: "Explore 6-Pack", href: "#" },
            },
            {
              id: "ID939341",
              properties: { text: "Explore 12-Pack", href: "#" },
            },
            {
              id: "ID939342",
              properties: { text: "Shop More", href: "#" },
            },
          ],
        },
      ],
    },
  },
  {
    id: "a90s87fd9a0s",
    properties: {
      text: "Energy Bars",
      href: "#",
      navigation: [
        {
          id: "ID9423094391",
          properties: {
            text: "GoBAR® Green Tea",
            href: "#",
          },
          subNavigation: [
            { id: "ID939348", properties: { text: "Learn More", href: "#" } },
          ],
        },
        {
          id: "ID94230943902",
          properties: {
            text: "Bigfoot® Cranberry & Pistachio",
            href: "#",
          },
          subNavigation: [
            { id: "ID939348", properties: { text: "Learn More", href: "#" } },
          ],
        },
        {
          id: "ID94230943903",
          properties: {
            text: "Yeti® Mint Chocolate Chip",
            href: "#",
          },
          subNavigation: [
            {
              id: "ID939348",
              properties: { text: "Learn More", href: "#" },
            },
          ],
        },
      ],
    },
  },
  {
    id: "oaisoid393",
    properties: {
      text: "Machines",
      href: "#",
      navigation: [
        {
          id: "ID94230943905",
          properties: {
            text: "GoBrew Dispenser",
            href: "#",
          },
          subNavigation: [
            { id: "ID9393b48", properties: { text: "Learn More", href: "#" } },
          ],
        },
        {
          id: "ID9423043943905",
          properties: {
            text: "Energy Smart Dispenser",
            href: "#",
          },
          subNavigation: [
            { id: "ID93bf9348", properties: { text: "Learn More", href: "#" } },
          ],
        },
        {
          id: "ID94230332943905",
          properties: {
            text: "Energy Dispenser",
            href: "#",
          },
          subNavigation: [
            { id: "ID939n348", properties: { text: "Learn More", href: "#" } },
          ],
        },
      ],
    },
  },
  {
    id: "asdf098979978d",
    properties: {
      text: "Accessories",
      href: "#",
      navigation: [
        {
          id: "ID94230943906",
          properties: {
            text: "Sunscreen",
            href: "#",
          },
          subNavigation: [
            {
              id: "ID939348",
              properties: { text: "Shop GoScreen", href: "#" },
            },
            {
              id: "ID939341",
              properties: { text: "Best Sunscreen of 2023", href: "#" },
            },
            {
              id: "ID939342",
              properties: { text: "Shop More", href: "#" },
            },
          ],
        },
        {
          id: "ID9423094a3906",
          properties: {
            text: "Lip Balm",
            href: "#",
          },
          subNavigation: [
            {
              id: "ID939p348",
              properties: { text: "Shop GoBalm", href: "#" },
            },
            {
              id: "ID939pp341",
              properties: { text: "Explore Seasonal Lip Balms", href: "#" },
            },
            {
              id: "ID9pppp39342",
              properties: { text: "Shop More", href: "#" },
            },
          ],
        },
        {
          id: "ID942309439zz06",
          properties: {
            text: "Water Bottle",
            href: "#",
          },
          subNavigation: [
            {
              id: "ID9zzz39348",
              properties: { text: "Shop Alpine Bottle", href: "#" },
            },
            {
              id: "IzzzD939341",
              properties: { text: "Shop More Water Bottles", href: "#" },
            },
            {
              id: "ID939342zz",
              properties: { text: "Shop All Hiking Accessories", href: "#" },
            },
          ],
        },
      ],
    },
  },
];

export { topLevelNavigation };
