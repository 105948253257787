import { LightningElement, api } from "lwc";
import { mockContactsData, mockOpportunitiesData } from "./mockData";

export default class DataTable extends LightningElement {
  static renderMode = `light`;

  @api isContacts;

  mockData;
  connectedCallback() {
    this.mockData = this.isContacts ? mockContactsData : mockOpportunitiesData;
  }
}
