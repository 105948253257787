import { api } from "lwc";
import PrimitiveButton from "primitive/button";
import template from "../../primitive/button/button.html";
import stylesheets from "./button.css";

export default class ComButton extends PrimitiveButton {
  privateAttr;
  @api
  get attr() {
    return this.privateAttr;
  }
  set attr(value) {
    this.privateAttr = value;
    this.setAttribute("attr", this.privateAttr);
  }
  render() {
    template.stylesheets = stylesheets;
    template.stylesheetToken = "whatever-you-want";
    return template;
  }
}
