import { LightningElement, track } from "lwc";
import { topLevelNavigation } from "./mockNav";
// external dependencies are also able to be imported/resolved.
// for example: import classNames from 'classnames';

export default class App extends LightningElement {
  static renderMode = `light`;
  navigation = topLevelNavigation;
  customerLinks = [
    {
      id: "24tiuh43tiu",
      properties: {
        text: "Need Help?",
        href: "#",
      },
    },
    {
      id: "f4f425",
      properties: {
        text: "US",
        href: "#",
      },
    },
    {
      id: "g533gd",
      properties: {
        text: "English",
        href: "#",
      },
    },
    {
      id: "dsfvdfbdfb",
      properties: {
        text: "Register",
        href: "#",
      },
    },
    {
      id: "dsfvdfbdfbs",
      properties: {
        text: "Login",
        href: "#",
      },
    },
  ];
}
