export const mockContactsData = [
  {
    id: "cloud-1",
    properties: [
      {
        value: "static/images/cloudkicks/avatars/brooklyn.png",
        isImage: true,
        label: "",
      },
      { value: "Brooklyn Simmons", label: "Name", isLink: true },
      { value: "Web Designer", label: "Title" },
      { value: "willie.jennings@example.com", label: "Email", isLink: true },
      { value: "(208) 555-0112", label: "Phone" },
    ],
  },
  {
    id: "cloud-2",
    properties: [
      {
        value: "static/images/cloudkicks/avatars/theresa.png",
        isImage: true,
        label: "",
      },
      { value: "Theresa Webb", label: "Name", isLink: true },
      { value: "Marketing Coordinator", label: "Title" },
      { value: "georgia.young@example.com", label: "Email", isLink: true },
      { value: "(808) 555-0111", label: "Phone" },
    ],
  },
  {
    id: "cloud-3",
    properties: [
      {
        value: "static/images/cloudkicks/avatars/floyd.png",
        isImage: true,
        label: "",
      },
      { value: "Floyd Miles", label: "Name", isLink: true },
      { value: "President of Sales", label: "Title" },
      { value: "tanya.hill@example.com", label: "Email", isLink: true },
      { value: "(704) 555-0127", label: "Phone" },
    ],
  },
  {
    id: "cloud-4",
    properties: [
      {
        value: "static/images/cloudkicks/avatars/savannah.png",
        isImage: true,
        label: "",
      },
      { value: "Savannah Nguyen", label: "Name", isLink: true },
      { value: "Dog Trainer", label: "Title" },
      { value: "tim.jennings@example.com", label: "Email", isLink: true },
      { value: "(671) 555-0110", label: "Phone" },
    ],
  },
  {
    id: "cloud-5",
    properties: [
      {
        value: "static/images/cloudkicks/avatars/arlene.png",
        isImage: true,
        label: "",
      },
      { value: "Arlene McCoy", label: "Name", isLink: true },
      { value: "Medical Assistant", label: "Title" },
      { value: "debbie.baker@example.com", label: "Email", isLink: true },
      { value: "(217) 555-0113", label: "Phone" },
    ],
  },
];

export const mockOpportunitiesData = [
  {
    id: "cloud-id-1",
    properties: [
      {
        value: "Add on $75k",
        label: "Name",
        isLink: true,
      },
      { value: "Closed - Won", label: "Stage" },
      { value: "$89.871", label: "Amount" },
      { value: "12/06/2020", label: "Close Date" },
    ],
  },
  {
    id: "cloud-id-2",
    properties: [
      {
        value: "Tesla - Mulesoft",
        label: "Name",
        isLink: true,
      },
      { value: "Pending", label: "Stage" },
      { value: "$35,343,000", label: "Amount" },
      { value: "16/08/2013", label: "Close Date" },
    ],
  },
  {
    id: "cloud-id-3",
    properties: [
      {
        value: "Tesla - Merge with Audi",
        label: "Name",
        isLink: true,
      },
      { value: "Closed - Lost", label: "Stage" },
      { value: "$25,000", label: "Amount" },
      { value: "15/08/2017", label: "Close Date" },
    ],
  },
  {
    id: "cloud-id-4",
    properties: [
      {
        value: "Upgrade Services - $30k ",
        label: "Name",
        isLink: true,
      },
      { value: "New", label: "Stage" },
      { value: "$525,834.12", label: "Amount" },
      { value: "18/09/2016", label: "Close Date" },
    ],
  },
  {
    id: "cloud-id-5",
    properties: [
      {
        value: "Top Secret Edit Button - $50k ",
        label: "Name",
        isLink: true,
      },
      { value: "Pending", label: "Stage" },
      { value: "$1,223,000", label: "Amount" },
      { value: "07/05/2016", label: "Close Date" },
    ],
  },
];
