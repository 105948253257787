import PrimitiveMenu from "primitive/menu";
import template from "../../primitive/menu/menu.html";
import stylesheets from "./menu.css";

export default class ComMenu extends PrimitiveMenu {
  render() {
    template.stylesheets = stylesheets;
    template.stylesheetToken = "menu";
    return template;
  }
}
