import { LightningElement, api } from "lwc";

export default class PrimitiveDropdown extends LightningElement {
  @api label;
  @api href;
  @api showOn = "hover";

  get _slottedChildren() {
    const slot = this.template.querySelector("slot[name=toggle]");
    return slot.assignedElements({ flatten: true });
  }

  setVisibilityAttributes(onEvent, offEvent, toggleElement) {
    const trigger = this.template.querySelector("[part='dropdown']");
    const item = this.template.querySelector("[part='item']");
    const overlay = this.template.querySelector("primitive-overlay");
    toggleElement.addEventListener(onEvent, (e) => {
      e.preventDefault();
      trigger.part.add("active");
      item.part.add("active");
      toggleElement.part.add("active");
      overlay.setAttribute("open", "");
      overlay.removeAttribute("closed");
    });
    toggleElement.addEventListener(offEvent, (e) => {
      trigger.part.remove("active");
      item.part.remove("active");
      toggleElement.part.remove("active");
      overlay.removeAttribute("open");
      overlay.setAttribute("closed", "");
    });
  }
  handleTriggerOnClick() {
    const action = this.template.querySelector("[part='action']");
    this.setVisibilityAttributes("focus", "blur", action);
  }

  handleTriggerOnHover() {
    const item = this.template.querySelector("[part='dropdown']");
    this.setVisibilityAttributes("mouseover", "mouseleave", item);
  }

  renderedCallback() {
    const item = this.template.querySelector("[part='item']");
    const slottedContent = this._slottedChildren;
    if (slottedContent.length === 1) {
      if (this.showOn === "hover")
        this.setVisibilityAttributes("mouseover", "mouseleave", item);
      if (this.showOn === "click")
        this.setVisibilityAttributes("focus", "blur", slottedContent[0]);
    } else {
      if (this.showOn === "click") this.handleTriggerOnClick();
      if (this.showOn === "hover") this.handleTriggerOnHover();
    }
  }
}
