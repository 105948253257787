import { LightningElement } from "lwc";
import { topLevelNavigation } from "./mockNav";
// external dependencies are also able to be imported/resolved.
// for example: import classNames from 'classnames';

export default class App extends LightningElement {
  static renderMode = `light`;
  navigation = topLevelNavigation;
  customerLinks = [
    {
      id: "24tiuh43tiu",
      properties: {
        text: "help",
        href: "#",
      },
    },
    {
      id: "f4f425",
      properties: {
        text: "exchanges & returns",
        href: "#",
      },
    },
    {
      id: "g533gd",
      properties: {
        text: "order tracker",
        href: "#",
      },
    },
    {
      id: "dsfvdfbdfb",
      properties: {
        text: "become a member",
        href: "#",
      },
    },
  ];
}
