import PrimitiveIcon from "primitive/icon";
import template from "../../primitive/icon/icon.html";
import stylesheets from "./icon.css";

export default class ComIcon extends PrimitiveIcon {
  // static stylesheets = [...super.stylesheets, stylesheets]
  render() {
    template.stylesheets = stylesheets;
    template.stylesheetToken = "icon";
    return template;
  }
}
