import { LightningElement, api } from "lwc";

export default class Tabs extends LightningElement {
  static renderMode = `light`;

  @api source;

  mockData;

  primaryLinks = [
    {
      id: "24tiuhd43tiu",
      text: "Related",
      isActive: true,
    },
    {
      id: "f4f23425",
      text: "Details",
    },
    {
      id: "g53365gd",
      text: "News",
    },
  ];

  secondaryLinks = [
    {
      id: "24tiuhd43tiu",
      text: "Activity",
      isActive: true,
    },
    {
      id: "f4f23425",
      text: "Chatters",
    },
  ];

  tertiaryLinks = [
    {
      id: "24tiuhd43ztiu",
      text: "New Task",
    },
    {
      id: "f4f234z25",
      text: "New Event",
    },
    {
      id: "f4f234azz25",
      text: "New Log a Call",
    },
    {
      id: "f4f234zsf25",
      text: "Email",
      isActive: true,
    },
  ];

  connectedCallback() {
    switch (this.source) {
      case "primary":
        this.mockData = this.primaryLinks;
        break;
      case "secondary":
        this.mockData = this.secondaryLinks;
        break;
      default:
        this.mockData = this.tertiaryLinks;
    }

    if (this.source === "tertiary") {
      console.log(this.classList.add("custom-tab-tertiary"));
    }
  }
}
