export default {
    'lightning.actionSprite': '/static/assets/icons/action-sprite/svg/symbols.svg',
    'lightning.actionSpriteRtl': '/static/css/assets/icons/action-sprite/svg/symbols.svg',
    'lightning.customSprite': '/static/css/assets/icons/custom-sprite/svg/symbols.svg',
    'lightning.customSpriteRtl': '/static/css/assets/icons/custom-sprite/svg/symbols.svg',
    'lightning.doctypeSprite': '/static/css/assets/icons/doctype-sprite/svg/symbols.svg',
    'lightning.doctypeSpriteRtl': '/static/css/assets/icons/doctype-sprite/svg/symbols.svg',
    'lightning.standardSprite': '/static/css/assets/icons/standard-sprite/svg/symbols.svg',
    'lightning.standardSpriteRtl': '/static/css/assets/icons/standard-sprite/svg/symbols.svg',
    'lightning.utilitySprite': '/static/css/assets/icons/utility-sprite/svg/symbols.svg',
    'lightning.utilitySpriteRtl': '/static/css/assets/icons/utility-sprite/svg/symbols.svg',
};