import Chroma from "chroma-js";

/**
 * Constant - palette lights
 */
const paletteLights = {
  95: 96,
  90: 91,
  80: 81,
  70: 71,
  65: 66,
  60: 61,
  50: 49,
  40: 39,
  30: 29,
  20: 19,
  15: 14,
  10: 8,
};

/**
 * Gets max chroma in sRGB space for submitted hue and light combination
 *
 * @param {Float} hue
 * @param {Float} light
 * @param {Float} chroma
 * @return {Float}
 */
const getMaxChromaForHueLight = (hue, light, chroma = 140) => {
  const color = Chroma([hue, chroma, light], "hcl");

  // protect against rounding errors
  if (light >= 100) return 0;

  return color.clipped()
    ? getMaxChromaForHueLight(hue, light, chroma - 1)
    : chroma;
};

/**
 * Gets max chroma in sRGB space for submitted hue (at most chromatic lightness)
 *
 * @param {Float} hue
 * @param {Float} light
 * @return {Float}
 */
const getMaxChromaForHue = (hue, light = 100) => {
  const currentMaxChroma = getMaxChromaForHueLight(hue, light);
  const nextMaxChroma = getMaxChromaForHueLight(hue, light - 1);

  if (nextMaxChroma > currentMaxChroma) {
    return getMaxChromaForHue(hue, light - 1);
  } else {
    return currentMaxChroma;
  }
};

/**
 * To get the relative chroma, we average out the chroma of the color
 * as it relates to the row, in addition to how it relates to the entire
 * hue palette
 *
 * @param {number} hue : numerical value for hue in hcl (0-360)
 * @param {number} chroma : raw chroma value in hcl
 * @returns {number} relative chroma (percentage)
 */
const getRelativeChroma = (hue, chroma, light) => {
  const maxChroma = getMaxChromaForHueLight(hue, light);
  const rowChromaPct = maxChroma > 0 ? chroma / maxChroma : 0;
  const paletteChromaPct = chroma / getMaxChromaForHue(hue);
  const relativeChroma = ((rowChromaPct + paletteChromaPct) / 2).toFixed(2);

  return Math.min(parseFloat(relativeChroma), 1);
};

/**
 *
 * @param {String} color
 * @return {Object}
 */
const getPaletteFromColor = (color) => {
  const hcl = Chroma(color).hcl();
  let palette = {};

  Object.keys(paletteLights).forEach((lightKey) => {
    const hue = Math.round(hcl[0]);
    const light = paletteLights[lightKey];
    const relativeChroma = getRelativeChroma(hue, hcl[1], hcl[2]);
    const maxChroma = getMaxChromaForHueLight(hue, light);
    let finalChroma = maxChroma * relativeChroma;

    // chroma max locks for certain colors
    if (light === paletteLights[0]) {
      finalChroma = Math.min(finalChroma, 10);
    }

    if (light === paletteLights[1]) {
      finalChroma = Math.min(finalChroma, 25);
    }

    if (light === paletteLights[2]) {
      finalChroma = Math.min(finalChroma, 50);
    }

    palette[lightKey] = Chroma([hue, finalChroma, light], "hcl").hex();
  });

  return {
    palette,
    seedColorProperties: {
      hclHue: hcl[0],
      hclChroma: hcl[1],
      hclLight: hcl[2],
      relativeChroma: getRelativeChroma(hcl[0], hcl[1], hcl[2]),
    },
  };
};

export {
  getMaxChromaForHueLight,
  getMaxChromaForHue,
  getRelativeChroma,
  getPaletteFromColor,
};
