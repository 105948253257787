import { LightningElement, api } from "lwc";

export default class Hero extends LightningElement {
  privateLayout;

  @api image;
  @api
  get layout() {
    return this.privateLayout;
  }
  set layout(value) {
    this.privateLayout = value;
    this.setAttribute("layout", this.privateLayout);
  }
}
