import { api } from "lwc";
import PrimitiveMenu from "primitive/menu";
import template from "../../primitive/menu/menu.html";
import stylesheets from "./nav.css";

export default class ComNav extends PrimitiveMenu {
  privateVariant;

  @api
  get variant() {
    return this.privateVariant;
  }
  set variant(variant) {
    this.privateVariant = variant;
    this.setAttribute("variant", this.privateVariant);
  }

  render() {
    template.stylesheets = stylesheets;
    template.stylesheetToken = "whatever-you-want";
    return template;
  }
}
