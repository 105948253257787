import { LightningElement, api } from "lwc";

export default class PrimitiveOverlay extends LightningElement {
  privateExportparts;

  @api
  get exportparts() {
    return this.privateExportparts;
  }
  set exportparts(exportparts) {
    this.privateExportparts = exportparts;
    this.setAttribute("exportparts", this.privateExportparts);
  }
  connectedCallback() {
    this.setAttribute("closed", "");
  }
}
