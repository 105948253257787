export const topLevelNavigation = [
  {
    id: "ID-123",
    properties: {
      text: "Home",
      href: "#",
    },
  },
  {
    id: "ID-1234",
    properties: {
      text: "Accounts",
      href: "#",
    },
  },
  {
    id: "ID-12345",
    properties: {
      text: "Leads",
      href: "#",
    },
  },
  {
    id: "ID-234",
    properties: {
      text: "Opportunities",
      href: "#",
      navigation: [
        {
          id: "ID-2345",
          properties: {
            text: "New Opportunity",
            href: "#",
          },
        },
        {
          id: "ID-2345",
          properties: {
            text: "Acme Inc.",
            href: "#",
          },
        },
      ],
    },
  },
  {
    id: "ID-345",
    properties: {
      text: "Contacts",
      href: "#",
    },
  },
  {
    id: "ID-3456",
    properties: {
      text: "Tasks",
      href: "#",
    },
  },
  {
    id: "ID-34567",
    properties: {
      text: "Calendar",
      href: "#",
    },
  },
  {
    id: "ID-456",
    properties: {
      text: "Dashboards",
      href: "#",
    },
  },
  {
    id: "ID-4567",
    properties: {
      text: "Reports",
      href: "#",
    },
  },
];
