import { LightningElement, api } from "lwc";
import Prism from "prismjs";

const domparser = new DOMParser();

export default class App extends LightningElement {
  renderedCallback() {
    const codeBlocks = this.template.querySelectorAll(".code-block");
    codeBlocks.forEach((raw) => {
      const codePreview = document.createElement("div");
      const code = Prism.highlight(raw.innerHTML, Prism.languages.html, "html");
      const html = domparser.parseFromString(
        `<pre class="language-html">${code}</pre>`,
        "text/xml"
      );
      codePreview.innerHTML = html.documentElement.outerHTML;
      raw.insertAdjacentElement("afterend", codePreview);
    });
  }
}
