/**
 * NOTE: THIS IS FOR DIDACTIC PURPOSES ONLY
 * READ THE OFFICIAL DOCUMENTATION TO FULLY UNDERSTANDING ALL THIS CONCEPTS
 */

import AdidasApp from "adidas/app";
import AlpineApp from "alpine/app";
import CloudkicksApp from "cloudkicks/app";
import CloudkickscrmApp from "cloudkickscrm/app";
import NikeApp from "nike/app";
import ComApp from "com/app";
import UAApp from "ua/app";
import OneApp from "one/app";
import EasyApp from "easy/app";
import PrimitiveApp from "primitive/app";
import Playground from "hooks/playground";
import tokens from "./tokens";
import configProviderService from "lightning/configProvider";
import { setFeatureFlagForTest } from "lwc";
import initHooks from "./hooks";

configProviderService({
  getToken: (name) => tokens[name],
});

setFeatureFlagForTest("ENABLE_MIXED_SHADOW_MODE", true);

// eslint-disable-next-line @lwc/lwc/no-document-query
const container = document.getElementById("main");
const nav = document.getElementById("nav");
const url = window.location.pathname;

switch (url) {
  case "/hooks":
    customElements.define(
      "hooks-playground",
      Playground.CustomElementConstructor
    );
    container.appendChild(document.createElement("hooks-playground"));
    break;
  case "/primitives":
    customElements.define(
      "primitive-app",
      PrimitiveApp.CustomElementConstructor
    );
    container.appendChild(document.createElement("primitive-app"));
    break;
  case "/unstyled":
    customElements.define("com-app", ComApp.CustomElementConstructor);
    container.appendChild(document.createElement("com-app"));
    break;
  case "/adidas":
    customElements.define("adidas-app", AdidasApp.CustomElementConstructor);
    container.appendChild(document.createElement("adidas-app"));
    break;
  case "/alpine":
    customElements.define("alpine-app", AlpineApp.CustomElementConstructor);
    container.appendChild(document.createElement("alpine-app"));
    break;
  case "/cloudkicks":
    customElements.define(
      "cloudkick-app",
      CloudkicksApp.CustomElementConstructor
    );
    container.appendChild(document.createElement("cloudkick-app"));
    break;
  case "/cloudkickscrm":
    customElements.define(
      "cloudkickscrm-app",
      CloudkickscrmApp.CustomElementConstructor
    );
    container.appendChild(document.createElement("cloudkickscrm-app"));
    break;
  case "/nike":
    customElements.define("nike-app", NikeApp.CustomElementConstructor);
    container.appendChild(document.createElement("nike-app"));
    break;
  case "/ua":
    customElements.define("ua-app", UAApp.CustomElementConstructor);
    container.appendChild(document.createElement("ua-app"));
    break;
  case "/one":
    customElements.define("one-app", OneApp.CustomElementConstructor);
    container.appendChild(document.createElement("one-app"));
    break;
  case "/easy":
    customElements.define("easy-app", EasyApp.CustomElementConstructor);
    container.appendChild(document.createElement("easy-app"));
    break;
  default:
    break;
}

const navLinks = nav.querySelectorAll("li a");
for (const item of navLinks) {
  item.pathname === url
    ? item.classList.add("active")
    : item.classList.remove("active");
}

initHooks();
