import PrimitiveTextInput from "primitive/textInput";
import template from "../../primitive/textInput/textInput.html";
import stylesheets from "./textInput.css";

export default class ComTextInput extends PrimitiveTextInput {
  render() {
    template.stylesheets = stylesheets;
    template.stylesheetToken = "text-input";
    return template;
  }
}
