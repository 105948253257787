import { LightningElement, track } from "lwc";
import { topLevelNavigation } from "./mockNav";
// external dependencies are also able to be imported/resolved.
// for example: import classNames from 'classnames';

export default class App extends LightningElement {
  static renderMode = `light`;
  navigation = topLevelNavigation;
  customerLinks = [
    {
      id: "24tiuh43tiu",
      properties: {
        text: "Find a Store",
        href: "#",
      },
    },
    {
      id: "f4f425",
      properties: {
        text: "Help",
        href: "#",
      },
    },
    {
      id: "g533gd",
      properties: {
        text: "Exchanges & Returns",
        href: "#",
      },
    },
    {
      id: "dsfvdfbdfb",
      properties: {
        text: "Join Us",
        href: "#",
      },
    },
  ];
}
