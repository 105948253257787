import { LightningElement } from "lwc";
import { setPaletteFromColor, setFontScale, setFontBase } from "../../hooks";

export default class Playground extends LightningElement {
  static renderMode = `light`;
  _hasRendered = false;

  renderedCallback() {
    if (this._hasRendered) return;
    this._hasRendered = true;
    this.handleInput();
    this.setDataValue();
  }

  updateUI = (el, value) => {
    const target = el.nextElementSibling;
    target.innerHTML = value;
  };

  setIncrement = (type, value, unit) => {
    let newValue = unit ? value + unit : value;
    document.documentElement.style.setProperty(`--sds-g-${type}`, newValue);
    if (type.includes("color")) {
      setPaletteFromColor(newValue, type);
    }
  };

  setDataValue() {
    const category = document.querySelectorAll(".example");
    category.forEach((el) => {
      const inlineValue = el.attributes.style.nodeValue;
      const customProp = inlineValue.match(/(--.+[^)])/)[0];
      const prop = inlineValue.match(/^([^:]+)/)[0];
      const computedValue = getComputedStyle(el).getPropertyValue(prop);
      if (customProp.includes("-g-color-") && !customProp.includes("seed")) {
        const range = customProp.match(/\b[0-9]+\b/)[0];
        el.setAttribute("data-range", range);
        if (range >= 50) {
          el.setAttribute("data-contrast", parseInt(range) - 50);
        } else {
          el.setAttribute("data-contrast", parseInt(range) + 60);
        }
      }
      el.setAttribute("data-custom-prop", customProp);
      el.setAttribute("data-value", `${customProp}: ${computedValue}`);
    });
  }

  handleInput() {
    const controls = document.querySelectorAll("input");
    controls.forEach((control) => {
      const element = document.getElementById(control.id);
      this.updateValue(element);
      element.addEventListener("change", (e) => {
        this.updateValue(element, e);
        this.setDataValue();
      });
      if (element.type !== "color") {
        element.addEventListener("input", (e) => {
          this.updateValue(element, e);
        });
      }
    });
  }

  updateValue = (el, e) => {
    const target = e ? e.target : el;
    this.setIncrement(el.name, target.value, el.dataset.unit);
    this.updateUI(target, target.value);
    if (el.name.includes("font-scale")) {
      setFontScale(target.value);
    }
    if (el.name.includes("font-size")) {
      setFontBase(target.value);
    }
  };
}
