import { LightningElement, api, track } from "lwc";

export default class PrimitiveButton extends LightningElement {
  privateVariant;

  @api accessKey;
  @api title;
  @api name;
  @api value;
  @api label;
  @api type = "button";
  @api
  get variant() {
    return this.privateVariant;
  }
  set variant(value) {
    this.privateVariant = value;
    this.setAttribute("variant", this.privateVariant);
  }
}
